<template>
  <a-card :bordered="false" class="card-area">
    <div class="page-form-advanced-search">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :md="5" :sm="24">
            <a-form-item
              label="合同号"
              :labelCol="{ span: 5 }"
              :wrapperCol="{ span: 18, offset: 1 }"
            >
              <a-input v-model="queryParams.contractNo" />
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24">
            <a-form-item
              label="项目名称"
              :labelCol="{ span: 5 }"
              :wrapperCol="{ span: 18, offset: 1 }"
            >
              <a-input v-model="queryParams.projectName" />
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24">
            <a-form-item
              label="工单号"
              :labelCol="{ span: 5 }"
              :wrapperCol="{ span: 18, offset: 1 }"
            >
              <a-input v-model="queryParams.taskNo" />
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24">
            <a-form-item
              label="报修类型"
              :labelCol="{ span: 5 }"
              :wrapperCol="{ span: 18, offset: 1 }"
            >
              <a-select
                show-search
                placeholder="选择紧急度"
                v-model="queryParams.type"
                style="width: 100%"
              >
                <a-select-option
                  v-for="t in taskType"
                  :key="t.keyy"
                  :value="t.keyy"
                  >{{ t.valuee }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24">
            <a-form-item
              label="紧急度"
              :labelCol="{ span: 5 }"
              :wrapperCol="{ span: 18, offset: 1 }"
            >
              <a-select
                show-search
                placeholder="选择紧急度"
                v-model="queryParams.urgency"
                style="width: 100%"
              >
                <a-select-option
                  v-for="t in urgency"
                  :key="t.keyy"
                  :value="t.keyy"
                  >{{ t.valuee }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24">
            <a-form-item
              label="状态"
              :labelCol="{ span: 5 }"
              :wrapperCol="{ span: 18, offset: 1 }"
            >
              <a-select
                show-search
                placeholder="选择状态"
                v-model="queryParams.status"
                style="width: 100%"
              >
                <a-select-option
                  v-for="s in status"
                  :key="s.keyy"
                  :value="s.keyy"
                  >{{ s.valuee }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12"> </a-col>
          <a-col :span="12" :style="{ textAlign: 'right' }">
            <a-button type="primary" @click="search">查询</a-button>
            <a-button style="margin-left: 8px" @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div>
      <div class="operator">
        <a-upload> </a-upload>
      </div>
      <!-- 表格区域 -->
      <a-table
        ref="TableInfo"
        :columns="columns"
        :rowKey="(record) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        bordered
        :scroll="{ x: 900 }"
      >
        <template slot="remark" slot-scope="text">
          <a-popover placement="topLeft">
            <template slot="content">
              <div style="max-width: 200px">{{ text }}</div>
            </template>
            <p style="width: 200px; margin-bottom: 0">{{ text }}</p>
          </a-popover>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a @click="confirmRepairs(record.id)" :disabled="record.status > 1">确认</a>
          <a-divider type="vertical" />
          <a @click="cancelRepairs(record.id)" :disabled="record.status > 1">撤销</a>
          <a-divider type="vertical" />
          <a @click="finishRepairs(record.id)" :disabled="record.status != 2">完成</a>
          <a-divider type="vertical" />
          <a @click="edit(record)">分配工单</a>
          <!-- <a-icon
            v-hasPermission="['repairs:update']"
            type="setting"
            theme="twoTone"
            twoToneColor="#4a9ff5"
            @click="edit(record)"
            title="报修维护"
          ></a-icon>
          <a-badge
            v-hasNoPermission="['repairs:update']"
            status="warning"
            text="无权限"
          ></a-badge> -->
        </template>
      </a-table>
      <repairs-detail
        ref="repairsDetail"
        @close="handleRepairsDetailClose"
        @success="handleRepairsDetailSuccess"
        :urgency="this.urgency"
        :status="this.status"
        :taskType="this.taskType"
        :repairsDetailVisiable="repairsDetailVisiable"
      >
      </repairs-detail>
    </div>
  </a-card>
</template>
<script>
import RepairsDetail from "./RepairsDetail";
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "repairs",
  components: { RepairsDetail },
  data() {
    return {
      dataSource: [],
      paginationInfo: null,
      pagination: {
        pageSizeOptions: ["10", "20", "30", "40", "100"],
        defaultCurrent: 1,
        defaultPageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) =>
          `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`,
      },
      queryParams: {},
      repairsDetailVisiable: false,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      taskType: (state) => state.dict.dicts.task_task_type,
      status: (state) => state.dict.dicts.repairs_status,
      urgency: (state) => state.dict.dicts.repairs_urgency,
    }),
    columns() {
      return [
        {
          title: "ID",
          dataIndex: "id",
          width: 80,
        },
        {
          title: "报修时间",
          dataIndex: "createTime",
          width: 160,
          customRender: (text, row, index) => {
            return moment(text).format("YYYY-MM-DD HH:mm:ss");
          },
        },
        {
          title: "报修类型",
          dataIndex: "type",
          customRender: (text, row, index) => {
            for (let _index in this.taskType) {
              let key = this.taskType[_index].keyy;
              if (text === key) {
                let val = this.taskType[_index].valuee;
                let color = this.taskType[_index].otherKeyy;
                return <a-badge color={color} text={val}></a-badge>;
              } else {
                continue;
              }
            }
            return text;
          },
          width: 150,
        },
        {
          title: "联系人",
          dataIndex: "contact",
          width: 120,
        },
        {
          title: "电话",
          dataIndex: "phone",
          width: 120,
        },
        {
          title: "地址",
          dataIndex: "address",
          width: 300,
          customRender: (text, row, index) => {
            let province = row.province == undefined ? '' : row.province
            let city = row.city == undefined ? '' : row.city
            let area = row.area == undefined ? '' : row.area
            let address = row.address == undefined ? '' : row.address

            return province + city + area + address;
          },
        },
        {
          title: "紧急度",
          dataIndex: "urgency",
          customRender: (text, row, index) => {
            for (let _index in this.urgency) {
              let key = this.urgency[_index].keyy;
              if (text === key) {
                let val = this.urgency[_index].valuee;
                let color = this.urgency[_index].otherKeyy;
                return <a-tag color={color}>{val}</a-tag>;
              } else {
                continue;
              }
            }
            return text;
          },
          width: 100,
        },
        {
          title: "状态",
          dataIndex: "status",
          customRender: (text, row, index) => {
            for (let _index in this.status) {
              let key = this.status[_index].keyy;
              if (text === key) {
                let val = this.status[_index].valuee;
                let color = this.status[_index].otherKeyy;
                return <a-tag color={color}>{val}</a-tag>;
              } else {
                continue;
              }
            }
            return text;
          },
          width: 100,
        },
        {
          title: "合同号",
          dataIndex: "contractNo",
          width: 150,
        },
        {
          title: "项目名称",
          dataIndex: "projectName",
          width: 300,
        },
        {
          title: "关联工单",
          dataIndex: "taskNo",
          width: 150,
        },
        {
          title: "操作",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
          width: 250,
        },
      ];
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    handleRepairsDetailClose() {
      this.repairsDetailVisiable = false;
    },
    handleRepairsDetailSuccess() {
      this.repairsDetailVisiable = false;
      this.search();
    },
    edit(record) {
      this.$refs.repairsDetail.setForm(record);
      this.repairsDetailVisiable = true;
    },
    search() {
      this.selectData({
        ...this.queryParams,
      });
    },
    reset() {
      // 重置分页
      this.$refs.TableInfo.pagination.current = this.pagination.defaultCurrent;
      if (this.paginationInfo) {
        this.paginationInfo.current = this.pagination.defaultCurrent;
        this.paginationInfo.pageSize = this.pagination.defaultPageSize;
      }
      this.paginationInfo = null;
      // 重置查询参数
      this.queryParams = {};
      this.fetch();
    },
    handleTableChange(pagination, filters, sorter) {
      this.paginationInfo = pagination;
      this.fetch({
        ...this.queryParams,
      });
    },
    fetch(params = {}) {
      this.loading = true;
      if (this.paginationInfo) {
        // 如果分页信息不为空，则设置表格当前第几页，每页条数，并设置查询分页参数
        this.$refs.TableInfo.pagination.current = this.paginationInfo.current;
        this.$refs.TableInfo.pagination.pageSize = this.paginationInfo.pageSize;
        params.pageSize = this.paginationInfo.pageSize;
        params.pageNum = this.paginationInfo.current;
      } else {
        // 如果分页信息为空，则设置为默认值
        params.pageSize = this.pagination.defaultPageSize;
        params.pageNum = this.pagination.defaultCurrent;
      }
      this.$get("repairs", {
        ...params,
      }).then((r) => {
        let data = r.data;
        const pagination = { ...this.pagination };
        pagination.total = data.total;
        this.loading = false;
        this.dataSource = data.rows;
        this.pagination = pagination;
      });
    },
    selectData(params = {}) {
      this.loading = true;
      // 如果分页信息为空，则设置为默认值
      this.$refs.TableInfo.pagination.current = this.pagination.defaultCurrent;
      this.$refs.TableInfo.pagination.pageSize = this.pagination.defaultPageSize;
      params.pageSize = this.pagination.defaultPageSize;
      params.pageNum = this.pagination.defaultCurrent;
      this.$get("repairs", {
        ...params,
      }).then((r) => {
        let data = r.data;
        const pagination = { ...this.pagination };
        pagination.total = data.total;
        this.loading = false;
        this.dataSource = data.rows;
        this.pagination = pagination;
      });
    },
    confirmRepairs(id) {
      this.$put(`repairs/confirm/${id}`).then((r) => {
          if (r.data.code == 200 ) {
            this.$message.success(r.data.message);
          }
          else {
            this.$message.warning(r.data.message);
          }
          this.search();
        });
    },
    cancelRepairs(id) {
      this.$put(`repairs/cancel/${id}`).then((r) => {
          if (r.data.code == 200 ) {
            this.$message.success(r.data.message);
          }
          else {
            this.$message.warning(r.data.message);
          }
          this.search();
        });
    },
    finishRepairs(id) {
      this.$put(`repairs/finish/${id}`).then((r) => {
          if (r.data.code == 200 ) {
            this.$message.success(r.data.message);
          }
          else {
            this.$message.warning(r.data.message);
          }
          this.search();
        });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../utils/Common.less";
</style>